































































import MethodParams from "@/includes/logic/Modules/modules/modules/ApiNotifyHandlerModule/components/MethodParams.vue";
import { BASE_API_URL } from "@/includes/constants";
import { apidata } from "./apidata";

import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue';
import MiniHelpMessage from "piramis-base-components/src/components/MiniHelpMessage/MiniHelpMessage.vue";

import { Vue, Component } from 'vue-property-decorator';

@Component({
  data() {
    return {
      apidata,
      BASE_API_URL
    }
  },
  components: {
    MethodParams,
    Accordion,
    MiniHelpMessage
  }
})
export default class ApiDocumentation extends Vue {
}
